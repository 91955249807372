import { withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from 'manage-tritag/components/layout'
import CreateForm from 'manage-tritag/components/pages/seasons/create-form'

const CreateSeasonPage = () => {
  return (
    <Layout label1="Seasons" label2="Create" url="/seasons">
      <CreateForm />
    </Layout>
  )
}

export default withAuthenticationRequired(CreateSeasonPage)
