import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Box,
  Typography,
  Select,
} from '@mui/material'
import { navigate } from 'gatsby'
import { toast } from 'react-toastify'
import {
  Season,
  useAddSeasonMutation,
} from 'manage-tritag/services/api/endpoints/seasons'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Spinner from 'manage-tritag/components/loading/spinner'
import { useMediaQuery } from 'react-responsive'
import {
  FormContent,
  StyledButton,
  StyledButtonDiv,
  StyledGridContainer,
  StyledTextField,
} from './styles'

const SeasonCreateForm = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 898px)` })
  const [createSeason, { isLoading: createLoading }] = useAddSeasonMutation()
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: '',
      startDate: '',
      endDate: '',
      currentSeason: '',
    },
  })

  const onSubmit: SubmitHandler<Season> = async (createData: any) => {
    await createSeason(createData).unwrap()
    reset()
    toast.success('Season added succesfully.')
    navigate('/seasons')
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <StyledGridContainer container spacing={3} justifyContent="space-between">
        <Grid item xs={12} sm={12} md={12}>
          <Typography sx={{ fontWeight: 'bold', color: '#008174' }}>
            Season details
          </Typography>
          <FormContent>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 1 }}
                  id="filled-basic"
                  label="Name"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  inputProps={{ tabIndex: 2 }}
                  label="Start date"
                  type="date"
                  size="small"
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                  required
                />
              )}
            />

            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  inputProps={{ tabIndex: 3 }}
                  label="End date"
                  type="date"
                  size="small"
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                  required
                />
              )}
            />
          </FormContent>
          <FormContent>
            <FormControl variant="filled" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Current season
              </InputLabel>
              <Controller
                name="currentSeason"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 4 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    size="small"
                    {...field}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="true">
                      <em>Yes</em>
                    </MenuItem>
                    <MenuItem value="false">
                      <em>No</em>
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            {!isMobile && (
              <>
                <Box sx={{ width: '100%' }} />
                <Box sx={{ width: '100%' }} />
              </>
            )}
          </FormContent>
        </Grid>
      </StyledGridContainer>
      <StyledButtonDiv>
        <div />

        <div>
          <StyledButton
            variant="outlined"
            onClick={() => {
              navigate('/seasons')
            }}
            sx={{
              border: '2px solid #008174',
              color: '#000',
              marginRight: '1.5rem',
            }}
          >
            CANCEL
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '83px' }}
          >
            {createLoading ? <Spinner size={22} /> : 'SAVE'}
          </StyledButton>
        </div>
      </StyledButtonDiv>
    </Box>
  )
}

export default SeasonCreateForm
